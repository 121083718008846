<template>
  <div id="otp">
    <Header></Header>
    <div class="breadcrumb-wrap bg-f br-1">
      <div class="overlay bg-black op-9"></div>
      <img :src="shape1" alt="Image" class="br-shape-1" />
      <img :src="shape2" alt="Image" class="br-shape-2" />
      <div class="container">
        <div class="row">
          <div class="col-xl-12">
            <div class="breadcrumb-title">
              <h2>Verify OTP</h2>
              <ul class="breadcrumb-menu list-style">
                <!--<li><a href="index-2.html">Home </a></li>-->
                <router-link to="/" style="color: white"
                  >Home &nbsp;&rarr;</router-link
                >
                <li>Verify OTP</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="login-wrap pt-100 pb-100">
      <img :src="shape1" alt="Image" class="login-shape-1" />
      <img :src="shape2" alt="Image" class="login-shape-2" />
      <div class="container">
        <div class="row">
          <div
            class="col-xl-6 offset-xl-3 col-lg-8 offset-lg-2 col-md-8 offset-md-2"
          >
            <div class="login-form">
              <div class="login-header bg-green">
                <h2 class="text-center mb-0">
                  Verification send on your email
                </h2>
              </div>
              <div class="login-body">
                <form class="form-wrap" @submit.prevent="verOtp">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="form-group">
                        <label for="otp">Enter OTP</label>
                        <input
                          id="otp"
                          name="otp"
                          type="otp"
                          v-model="form.otp"
                          placeholder="Enter OTP"
                          required=""
                        />
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-group">
                        <button class="btn style1" type="submit">
                          Verify OTP
                        </button>
                      </div>
                    </div>
                    <div class="text-center mb-3">
                      <a class="cursor pointer" @click="resend_otp"
                        ><span class="font-weight-bold text-danger cursor"
                          >Resend OTP</span
                        ></a
                      >
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer></Footer>
  </div>
</template>
<script>
import Header from "@/views/layouts/Header.vue";
import Footer from "@/views/layouts/Footer.vue";
import { getLocalStorage, saveLocalStorage } from "@/store/service";
import { verifyOtp, resendVerifyOtp } from "@/store/api";
//import Mainslider from './layouts/Mainslider.vue';
export default {
  name: "OtpVerify",
  data() {
    return {
      shape1: require("@/assets/img/shape-1.png"),
      shape2: require("@/assets/img/shape-2.png"),
      form: {
        email: getLocalStorage("userData").email,
        phone: getLocalStorage("userData").phone,
        otp: "",
      },
    };
  },
  components: {
    Header,
    Footer,
  },
  methods: {
    verOtp() {
      verifyOtp(this.form)
        .then((res) => {
          if (res.data.valid_otp === true) {
            saveLocalStorage("userDataVerify", "true");
            const userData = getLocalStorage("userData");
            userData.verified_at = new Date();
            saveLocalStorage("userData", JSON.stringify(userData));
            this.$toast.success("Registered successfully");
            this.$router.push("/myaccount");
          } else {
            this.$toast.error("Invalid Otp");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    resend_otp() {
      resendVerifyOtp(this.form).then((res) => {
        console.log(res);
        this.$toast.success("Send resend otp successfully");
      });
    },
    logout() {
      localStorage.removeItem("userData");
      localStorage.removeItem("cart");
      localStorage.removeItem("userDataVerify");
      this.$router.push("/");
    },
  },
};
</script>
